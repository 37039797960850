import ShippingClass from '../loadables/Settings/ShippingClass/ShippingClass';
import Carriers from '@assets/pages/Settings/Carriers/Carrier';
import Warehouse from '@assets/loadables/Settings/Warehouse';
import News from '@assets/loadables/Settings/News';
import Notifications from '@assets/loadables/Settings/Notifications';

export default [
  {
    path: '/notifications',
    component: Notifications,
    scope: 'settings.notifications.view'
  },
  {
    path: '/shipping-class',
    component: ShippingClass,
    scope: 'settings.shipping-class.shipping-class.view'
  },
  {path: '/carriers', component: Carriers, scope: 'settings.shipping-class.carrier.view'},
  {
    path: '/ware-house',
    component: Warehouse,
    scope: 'settings.ware_house.view'
  },
  {
    path: '/news',
    component: News,
    scope: 'settings.news.view'
  }
];
