import {setToast} from '@assets/actions/layout/setToastAction';
import ButtonActions from '@assets/components/Permission/ButtonActions';
import {carrierColumns, defaultCarrier} from '@assets/config/carrierConfig';
import {carrierRules} from '@assets/config/validations/carrierRules';
import {getUserToken} from '@assets/helpers';
import {formatDateOnly} from '@assets/helpers/utils/formatFullTime';
import useCreateApi from '@assets/hooks/api/useCreateApi';
import useDeleteApi from '@assets/hooks/api/useDeleteApi';
import useEditApi from '@assets/hooks/api/useEditApi';
import useFetchResourceList from '@assets/hooks/grid/useFetchResourceList';
import useConfirmModal from '@assets/hooks/modal/useConfirmModal';
import {store} from '@assets/index';
import CarrierCreate from '@assets/pages/Settings/Carriers/CarrierCreate';
import CarrierEdit from '@assets/pages/Settings/Carriers/CarrierEdit';
import {Button, ButtonGroup, Card, Layout, Page, Stack} from '@shopify/polaris';
import {ArrowLeftMinor} from '@shopify/polaris-icons';
import {connect} from 'react-redux';
import {checkPermission} from '@assets/helpers/user/permission';
const Carrier = ({user}) => {
  const token = getUserToken(user);
  const {deleting, handleDelete} = useDeleteApi({url: '/carrier/delete', token});
  const {creating: createLoading, handleCreate} = useCreateApi({
    url: '/carrier/create',
    token: token
  });
  const {editing: editLoading, handleEdit} = useEditApi({url: '/carrier/update', token: token});

  const {openModal: openCreateCarrier, modal: createCarrier} = useConfirmModal({
    buttonTitle: 'Save',
    title: 'Create Carrier',
    validationRules: carrierRules,
    closeTitle: 'Close',
    loading: createLoading,
    confirmAction: async input => {
      const {name, code, url} = input;
      const success = await handleCreate({
        name,
        code,
        url
      });
      if (success) {
        reFetch();
        store.dispatch(setToast({content: 'Create Successfully!'}));
      } else {
        return false;
      }
      return true;
    },
    HtmlContent: ({input, validations}) => <CarrierCreate {...{input, validations: validations}} />
  });

  const {openModal: openEditCarrier, modal: editCarrier} = useConfirmModal({
    buttonTitle: 'Save',
    title: 'Edit Carrier',
    validationRules: carrierRules,
    closeTitle: 'Close',
    loading: editLoading,
    resetButton: true,
    confirmAction: async input => {
      const {id, name, code, url} = input;
      const success = await handleEdit({
        id,
        name,
        code,
        url
      });
      if (success) {
        reFetch();
        store.dispatch(setToast({content: 'Edit Successfully!'}));
      } else {
        return false;
      }
      return true;
    },
    HtmlContent: ({input, validations}) => (
      <CarrierEdit
        {...{
          input,
          validations: validations
        }}
      />
    )
  });

  const renderItemCols = item => {
    const {id, name, code, url, created_at} = item;
    const key = id + '_';
    return [
      name,
      code,
      url ? url : '',
      formatDateOnly(created_at),
      <ButtonActions
        key={key + 5}
        canDelete={checkPermission(user, 'settings.shipping-class.carrier.delete')}
        canEdit={checkPermission(user, 'settings.shipping-class.carrier.edit')}
        handleEdit={() => openEditCarrier(item)}
        handleDelete={() => openDeleteModal(id)}
      />
    ];
  };

  const {renderList, footer, searchBar, reFetch} = useFetchResourceList({
    searchLabel: 'Search by name or code',
    fetchUrl: '/carrier/index',
    columns: carrierColumns,
    renderItemCols,
    token
  });

  const {modal: deleteModal, openModal: openDeleteModal} = useConfirmModal({
    loading: deleting,
    confirmAction: handleDelete,
    successCallback: reFetch,
    destructive: true
  });

  return (
    <Page
      fullWidth
      title="Carriers"
      primaryAction={
        <ButtonGroup>
          <Button icon={ArrowLeftMinor} url={'/shipping-class'}>
            Back to Shipping Class
          </Button>
          <Button
            disabled={!checkPermission(user, 'settings.shipping-class.carrier.edit')}
            primary
            onClick={() => openCreateCarrier(defaultCarrier)}
          >
            Create Carrier
          </Button>
        </ButtonGroup>
      }
    >
      <Layout sectioned>
        <Card>
          <Card.Section>
            <Stack spacing="tight">
              <Stack.Item fill>{searchBar}</Stack.Item>
            </Stack>
          </Card.Section>
          <div className="HCC--CarrierList__Body">{renderList}</div>
          {footer}
        </Card>
      </Layout>
      {createCarrier}
      {deleteModal}
      {editCarrier}
    </Page>
  );
};

export default connect(({user}) => ({user}), null)(Carrier);
