import {useEffect, useState} from 'react';
import Http from '@assets/services/Http';

/**
 * useFetchApi hook for fetch data from api with url
 *
 * @param url
 * @param defaultData
 * @param presentData
 * @param initLoad
 * @param method
 * @param token
 * @param queryParams
 * @param postData
 * @returns {{fetchApi: ((function(*, null=): Promise<AxiosResponse<any>|*|undefined>)|*), setFetched: (value: (((prevState: boolean) => boolean) | boolean)) => void, data: *[], setData: (value: (((prevState: *[]) => *[]) | *[])) => void, pageInfo: {}, count: number, extraCount: {}, additionalData: {}, loading: boolean, setCount: (value: (((prevState: number) => number) | number)) => void, fetched: boolean}}
 */
export default function useFetchApi({
  url,
  defaultData = [],
  presentData = null,
  initLoad = true,
  method = '',
  token = '',
  queryParams = {},
  postData = {}
}) {
  const [loading, setLoading] = useState(initLoad);
  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState(defaultData);
  const [additionalData, setAdditionalData] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [count, setCount] = useState(0);
  async function fetchApi(apiUrl, params = null) {
    try {
      setLoading(true);
      const path = apiUrl || url;
      const resp = await Http({
        url: path,
        method: method,
        token,
        params: {...queryParams, ...params},
        data: postData
      });
      if (resp.hasOwnProperty('additional_data')) {
        setAdditionalData(resp.additional_data);
      }
      if (resp.hasOwnProperty('data')) {
        const newData = presentData ? presentData(resp.data) : resp.data;
        setData(Array.isArray(newData) ? newData : {...defaultData, ...newData});
      }
      if (
        resp.hasOwnProperty('cur_page') &&
        resp.hasOwnProperty('per_page') &&
        resp.hasOwnProperty('total')
      ) {
        setPageInfo({
          curPage: resp.cur_page,
          perPage: resp.per_page,
          totalItem: resp.total
        });
      }
      return resp;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setFetched(true);
    }
  }

  useEffect(() => {
    if (initLoad) {
      fetchApi().then(() => {});
    }
  }, []);

  return {
    fetchApi,
    data,
    additionalData,
    setData,
    pageInfo,
    count,
    setCount,
    loading,
    fetched,
    setFetched
  };
}
