import React, {useEffect, useRef, useState} from 'react';
import {Modal} from '@shopify/polaris';
import useFormValidation from '@assets/hooks/service/useValidation';
import sheetBackdrop from '@assets/helpers/utils/sheetBackdrop';

/**
 * @param confirmAction
 * @param cancelAction
 * @param title
 * @param HtmlTitle
 * @param content
 * @param HtmlContent
 * @param large
 * @param loading
 * @param destructive
 * @param buttonTitle
 * @param closeTitle
 * @param disabled
 * @param sectioned
 * @param canCloseAfterFinished
 * @param successCallback
 * @param closeCallback
 * @param validationRules
 * @param defaultCurrentInput
 * @param {{content, loading, disabled, onAction}[]} moreActions
 * @param hideButton
 * @returns {{openModal, closeModal, modal: JSX.Element, open: boolean, validations}}
 */
export default function useConfirmModal({
  confirmAction,
  cancelAction,
  title = 'Are you sure to delete?',
  HtmlTitle = null,
  content = 'Please be careful because you cannot undo this action.',
  HtmlContent = null,
  buttonTitle = 'Confirm',
  closeTitle = 'Cancel',
  large = false,
  loading = false,
  disabled = false,
  destructive = false,
  sectioned = true,
  canCloseAfterFinished = true,
  successCallback = () => {},
  closeCallback = () => {},
  validationRules = () => {},
  defaultCurrentInput = null,
  moreActions = [],
  resetButton = false,
  resetTitle = 'Reset',
  hideButton = false,
  afterValidationAction = () => {},
  escCloseModal = true
}) {
  const {validations, handleValidateForm} = useFormValidation();
  const [open, setOpen] = useState(false);
  const input = useRef(null);
  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    sheetBackdrop({open});
  }, [open]);

  const openModal = (currentInput = defaultCurrentInput) => {
    input.current = currentInput;
    setOpen(true);
    setOriginalData(currentInput);
  };

  const closeModal = () => {
    if (!loading) setOpen(false);
  };

  const handleClose = () => {
    closeModal();
    closeCallback();
  };

  const handleResetData = () => {
    input.current = originalData;
    setOriginalData({...originalData});
  };

  const handleConfirm = () => {
    if (!handleValidateForm(validationRules(input.current))) {
      afterValidationAction();
      return;
    }
    confirmAction(input.current).then(success => {
      if (!success) return;
      if (canCloseAfterFinished) handleClose();
      successCallback(success);
    });
  };

  const secondaryActions = () => {
    if (hideButton) {
      return null;
    }

    const actionList = [
      {
        disabled: loading,
        content: closeTitle,
        onAction: () => (cancelAction ? cancelAction() : handleClose())
      }
    ];
    if (resetButton) {
      actionList.push({
        content: resetTitle,
        onAction: () => handleResetData()
      });
    }

    return [
      ...actionList,
      ...moreActions.map(action => ({
        ...action,
        onAction: () => action.onAction(input.current)
      }))
    ];
  };
  const primaryAction = () => {
    if (hideButton) {
      return null;
    }
    return {
      content: buttonTitle,
      loading,
      disabled,
      destructive,
      onAction: () => handleConfirm()
    };
  };
  const modal = (
    <Modal
      sectioned={sectioned}
      open={open}
      large={large}
      onClose={e => (escCloseModal || e.which !== 27 ? handleClose(e) : {})}
      title={HtmlTitle ? <HtmlTitle input={input} /> : title}
      primaryAction={primaryAction()}
      secondaryActions={secondaryActions()}
    >
      {HtmlContent ? <HtmlContent {...{input, validations}} /> : content}
    </Modal>
  );

  return {modal, open, closeModal, openModal, validations};
}
