export const carrierColumns = [
  {label: 'Name'},
  {label: 'Code'},
  {label: 'URL'},
  {label: 'Created At'},
  {label: 'Actions'}
];

export const defaultCarrier = {
  name: '',
  code: '',
  url: ''
};
