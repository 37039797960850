import {Button, ButtonGroup, Scrollable} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import React from 'react';

/**
 * @param handleClose
 * @param handleDelete
 * @param handleEdit
 * @param handleReset
 * @param handleSave
 * @param moreActions
 * @param loading
 * @param sectioned
 * @param classNames
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function SheetBody({
  handleClose,
  handleDelete,
  handleEdit,
  handleReset,
  handleSave,
  moreActions = [],
  loading = false,
  sectioned = true,
  classNames = [],
  children
}) {
  const allClasses = [
    'HCC-Sheet__Body',
    sectioned ? 'HCC-Sheet__BodySection' : '',
    ...classNames
  ].filter(x => x);

  return (
    <>
      <Scrollable className={allClasses.join(' ')} shadow>
        {children}
      </Scrollable>
      <div className="HCC-Sheet__Footer">
        <ButtonGroup>
          {handleClose && (
            <Button disabled={loading} onClick={() => !loading && handleClose()}>
              Close
            </Button>
          )}
          {handleReset && (
            <Button disabled={loading} onClick={() => handleReset()}>
              Reset
            </Button>
          )}
          {handleDelete && (
            <Button disabled={loading} destructive onClick={() => handleDelete()}>
              Delete
            </Button>
          )}
          {handleEdit && (
            <Button disabled={loading} primary onClick={() => handleEdit()}>
              Edit
            </Button>
          )}
          {handleSave && (
            <Button loading={loading} primary onClick={() => handleSave()}>
              Save
            </Button>
          )}
          {moreActions?.map?.((action, key) => (
            <Button
              key={key}
              icon={action.icon}
              primary={action.primary}
              loading={action.loading || loading}
              disabled={action.disabled || loading}
              onClick={() => action.onClick()}
            >
              {action.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
}

SheetBody.propTypes = {
  handleClose: PropTypes.func,
  handleDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleReset: PropTypes.func,
  handleSave: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  loading: PropTypes.bool,
  classNames: PropTypes.array,
  children: PropTypes.node
};
