const CollapseIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0131575 6.11063C0.0381639 6.23566 0.103806 6.35444 0.19758 6.44509C0.206957 6.45447 0.21946 6.46697 0.231963 6.47635C0.328863 6.55762 0.453895 6.59201 0.578927 6.59826C0.582053 6.59826 0.585179 6.59826 0.585179 6.59826C0.635192 6.60138 0.685204 6.59826 0.738343 6.59826C4.25799 6.59826 7.77764 6.59826 11.2973 6.59826C11.3973 6.59826 11.5036 6.58888 11.5974 6.56075C11.8599 6.47635 12.0225 6.21378 11.9975 5.94184C11.9725 5.66989 11.7599 5.44796 11.4848 5.41045C11.4192 5.40108 11.3536 5.40108 11.2848 5.40108C7.77139 5.40108 4.26112 5.4042 0.74772 5.39795C0.485153 5.39795 0.210083 5.46984 0.0694219 5.71366C0.0537929 5.74179 0.0381639 5.77305 0.0256607 5.80743C-0.00247151 5.9012 -0.00872311 6.00748 0.0131575 6.11063Z"
        fill="white"
      />
      <path
        d="M0.019778 1.40399C0.0385328 1.5384 0.1073 1.66656 0.216703 1.75408C0.335484 1.84785 0.488648 1.89786 0.638686 1.89786C3.21435 1.89786 5.78688 1.89786 8.36254 1.89786C8.69075 1.89786 8.93456 1.70094 8.99082 1.39774C9.04084 1.12579 8.88142 0.838219 8.61573 0.741319C8.54071 0.713187 8.45631 0.700684 8.37504 0.700684C5.79 0.700684 3.20497 0.700684 0.619932 0.700684C0.385497 0.700684 0.176068 0.850722 0.0729166 1.05702C0.019778 1.1633 0.00102318 1.28833 0.019778 1.40399Z"
        fill="white"
      />
      <path
        d="M0.0255756 10.8213C0.08184 11.0776 0.294394 11.2996 0.606974 11.2996C1.13523 11.2996 1.66037 11.2996 2.18863 11.2996C3.44833 11.2996 4.71115 11.2996 5.97085 11.2996C6.29906 11.2996 6.54599 11.0964 6.58975 10.7932C6.64289 10.4337 6.3772 10.1118 6.01461 10.1024C5.82081 10.0961 5.62388 10.1024 5.43008 10.1024C3.86406 10.1024 2.29803 10.1024 0.732006 10.1024C0.563213 10.1024 0.375665 10.1118 0.23813 10.2212C0.0412046 10.3743 -0.0213114 10.6088 0.0255756 10.8213Z"
        fill="white"
      />
    </svg>
  );
};
export default CollapseIcon;
