import {store} from '@assets/index';
import {Frame, Loading, Scrollable, Toast} from '@shopify/polaris';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {closeToast, setToast} from '../../actions/layout/setToastAction';
import AppNavigation from './AppNavigation';
import AppTopBar from './AppTopBar';
/**
 * Render an app layout
 *
 * @param {React.ReactNode} children
 * @param {Object} layout
 * @param {User.Info} user
 * @param {Function} setToast
 * @param {Function} closeToast
 * @param {Object} location
 * @param {Object} history
 * @return {JSX.Element}
 * @constructor
 */
function AppLayout({children, layout = {}, user, setToast, closeToast}) {
  useEffect(() => {
    store.dispatch(closeToast());
  }, []);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const toggleOpenNav = () => setIsNavOpen(prev => !prev);
  const navigationClass = [
    'HCC-ScrollBar--isSubdued',
    'HCC-Frame__Navigation',
    isNavOpen && 'HCC-Frame__Navigation--isExpanded'
  ].filter(Boolean);

  const contentClass = ['HCC-Frame__Content', isNavOpen && 'HCC-Frame__Content--isExpanded'].filter(
    Boolean
  );
  return (
    <>
      <Frame topBar={<AppTopBar {...{isNavOpen, toggleOpenNav}} />}>
        <div className="HCC-Frame">
          <Scrollable className={navigationClass.join(' ')}>
            <AppNavigation />
          </Scrollable>
          <div className={contentClass.join(' ')}>
            <div className="HCC-Frame__Content--DisplayContent">{children}</div>
            <footer className="HCC-Frame__Footer">
              <span>© 2019 - 2022 All Rights reserved. Highcommerce Co., Ltd.</span>
            </footer>
          </div>
        </div>
        {layout.loading && <Loading />}
        {layout.isToast && <Toast onDismiss={closeToast} {...layout.toast} duration={10000} />}
      </Frame>
    </>
  );
}

const mapStateToProps = app => ({
  layout: app.layout,
  user: app.user
});

export default connect(mapStateToProps, {setToast, closeToast})(AppLayout);
