import DateOptionsFilter from '../components/Grid/Filter//DateOptionsFilter';
import {USER_ROLE_ADMIN, USER_ROLE_SELLER, USER_ROLE_SUPPLIER} from '../const/user/roles';
import {allCountries} from '@assets/const/countries';
export const findStatus = key => userStatuses.find(s => s.value === key) || {};

export const userColumns = tab => {
  switch (tab) {
    case 0:
      return [
        {checkbox: true},
        {label: 'ID'},
        {label: 'Name'},
        {label: 'Email'},
        {label: 'Phone Number'},
        {label: 'Net Sold'},
        {label: 'Created Date'},
        {label: 'Status'},
        {label: 'Actions'}
      ];
    case 1:
      return [
        {checkbox: true},
        {label: 'ID'},
        {label: 'Name'},
        {label: 'Email'},
        {label: 'Phone Number'},
        {label: 'Origin'},
        {label: 'Created Date'},
        {label: 'Status'},
        {label: 'Actions'}
      ];
    case 2:
      return [
        {checkbox: true},
        {label: 'ID'},
        {label: 'Name'},
        {label: 'Email'},
        {label: 'Phone Number'},
        {label: 'Role'},
        {label: 'Created Date'},
        {label: 'Status'},
        {label: 'Actions'}
      ];
  }
};

export const userTabs = [
  {id: USER_ROLE_SELLER, content: 'Sellers', value: 'seller'},
  {id: USER_ROLE_SUPPLIER, content: 'Suppliers', value: 'supplier'},
  {id: USER_ROLE_ADMIN, content: 'Admins', value: 'admin'}
];

export const userStatuses = [
  {
    label: 'Inactive',
    value: 0,
    badge: 'default',
    state: 'disabled'
  },
  {
    label: 'Active',
    value: 1,
    badge: 'success'
  },
  {
    label: 'Deleted',
    value: 2,
    badge: 'default',
    hidden: true
  }
];
export const findUserStatus = status => {
  return userStatuses[status];
};
const userCreatedAtChoices = [
  {label: 'Last 7 days', value: '7_day'},
  {label: 'This month', value: '1_month'}
];
export const userFilters = () => [
  {
    key: 'status',
    label: 'Status',
    allowMultiple: false,
    choices: userStatuses.map(({label, value}) => ({label, value})),
    shortcut: true
  },
  {
    key: 'time',
    label: 'Created Date',
    shortcut: true,
    choices: userCreatedAtChoices,
    allowMultiple: false,
    renderFilter: props => <DateOptionsFilter {...props} choices={userCreatedAtChoices} />
  }
];
export const userStatusVisible = (state = '') =>
  userStatuses.filter(s => !s.hidden && (!s.state || !state || s.state === state));

export const userSortOrders = [
  {label: 'ID (newest first)', order_by: 'id', order_dir: 'desc'},
  {label: 'ID (oldest first)', order_by: 'id', order_dir: 'asc'},
  {label: 'Created Date (oldest first)', order_by: 'created_at', order_dir: 'asc'},
  {label: 'Created Date (newest first)', order_by: 'created_at', order_dir: 'desc'}
];

const timezone = 'Asia/Bangkok';
export const defaultAdmin = {
  timezone,
  account_type: 'admin',
  role_id: ''
};
export const defaultSeller = {
  timezone,
  account_type: 'seller',
  country: ''
};
export const defaultSupplier = {
  timezone,
  account_type: 'supplier',
  country: ''
};

export const defaultChangePasswordData = {
  oldPassword: '',
  password: '',
  rePassword: ''
};

export function findCountryNameByCode(code) {
  if (code) {
    const prepareCode = code.toUpperCase();
    return allCountries[prepareCode];
  } else {
    return null;
  }
}
