const PRIMARY_COLOR = '#279CF1';
import Logo from '../resources/logo/HCbanner.png';
const theme = {
  colors: {
    primary: PRIMARY_COLOR,
    secondary: 'white'
  },
  logo: {
    url: '/home',
    width: 150,
    topBarSource: Logo,
    accessibilityLabel: 'High Commerce'
  }
};

export default theme;
