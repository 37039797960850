/**
 * @param data
 * @returns {{code: {value, validation: string}, name: {value, validation: string}}}
 */
export const carrierRules = data => ({
  name: {
    value: data.name,
    validation: 'required'
  },
  code: {
    value: data.code,
    validation: 'required'
  }
});
