import React from 'react';
import {TextField, FormLayout} from '@shopify/polaris';

const CarrierForm = ({handleChangeData, carrier, validations}) => {
  return (
    <FormLayout>
      <TextField
        requiredIndicator
        value={carrier.name}
        error={validations.name?.message}
        onChange={value => handleChangeData('name', value)}
        label="Name"
        autoComplete="off"
      />

      <TextField
        requiredIndicator
        value={carrier.code}
        error={validations.code?.message}
        onChange={value => handleChangeData('code', value)}
        label="Code"
        autoComplete="off"
      />
      <TextField
        value={carrier.url}
        onChange={value => handleChangeData('url', value)}
        label="URL"
        autoComplete="off"
      />
    </FormLayout>
  );
};

export default CarrierForm;
