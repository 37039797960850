import {setLoading} from '@assets/actions/layout/setLoadingAction';
import {login, logout} from '@assets/actions/user/userAction';
import {createMessage} from '@assets/const/toast/toastConst';
import {history} from '@assets/helpers';
import {store} from '@assets/index';
import {
  ChangePassword,
  CreatePassword,
  LoginApi,
  LogoutApi,
  SendMailResetPassword,
  VerifyCreateToken
} from '@assets/services/Api/AuthApi';

export async function handleLogin(email, password) {
  store.dispatch(setLoading(true));
  const resp = await LoginApi(email, password);
  store.dispatch(setLoading(false));
  if (resp.data) {
    store.dispatch(login(resp));
    return {success: true};
  } else {
    return {success: false};
  }
}

export async function handleLogout(token) {
  store.dispatch(setLoading(true));
  const {success} = await LogoutApi(token);
  if (success) {
    store.dispatch(logout());
    window.localStorage.clear();
    store.dispatch(setLoading(false));
    createMessage('Logout successfully !!');
    history.push('/');
  }
}

export async function verifyEmail(code) {
  return VerifyCreateToken(code);
}

export async function handleCreatePassword(code, password, isCreate = false) {
  return CreatePassword(code, password, isCreate);
}

export async function requestResetPassword(email) {
  return SendMailResetPassword(email);
}

export async function handleChangePassword(email, old_password, password, token) {
  return ChangePassword(email, old_password, password, token);
}
