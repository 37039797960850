import {setToast} from '@assets/actions/layout/setToastAction';
import {store} from '@assets/index';

export function createMessage(content = 'Edit Successfully !') {
  return store.dispatch(setToast({content: content}));
}

export function createErrorMessage(content = '') {
  return store.dispatch(setToast({error: true, content: content}));
}
