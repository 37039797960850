export const userTypes = {
  LOGIN: '@@user/LOGIN',
  LOGOUT: '@@user/LOGOUT'
};

/**
 * Set user action
 *
 * @param {object} user
 * @return {{payload: *, type: string}}
 */
export function login(user) {
  return {type: userTypes.LOGIN, payload: user};
}
export function logout() {
  return {type: userTypes.LOGOUT};
}
