import React from 'react';
import {EmptyState, Layout, Page} from '@shopify/polaris';
import {useHistory} from 'react-router-dom';

/**
 * NotFound page is shown when BrowserRoute doesn't match any defined routes
 *
 * @return {React.ReactElement}
 * @constructor
 */
export default function NotFound({retry}) {
  const history = useHistory();

  return (
    <Page>
      <Layout sectioned>
        <EmptyState
          heading="The page you're looking for is under construction"
          action={{content: 'Back', onAction: () => history.goBack()}}
          // secondaryAction={{content: 'Go to Home', url: '/'}}
          image="https://pngimg.com/uploads/under_construction/under_construction_PNG18.png"
          imageContained
        >
          {`This is absolutely not a technical or network problem. Don't try reloading this page or something like that. Press F5 too much may cause your finger broken 🤪🤪.`}
        </EmptyState>
      </Layout>
    </Page>
  );
}
