import {setToast} from '@assets/actions/layout/setToastAction';
import {login} from '@assets/actions/user/userAction';
import SheetBody from '@assets/components/Sheet/SheetBody';
import SheetHeader from '@assets/components/Sheet/SheetHeader';
import UserForm from '@assets/components/User/Form/UserForm';
import {userRules} from '@assets/config/validations/userRules';
import {createMessage} from '@assets/const/toast/toastConst';
import cleanEmptyField from '@assets/helpers/utils/cleanEmptyField';
import useEditApi from '@assets/hooks/api/useEditApi';
import useFormValidation from '@assets/hooks/service/useValidation';
import {store} from '@assets/index';
import {DisplayText, Stack} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import {useState} from 'react';
import {connect} from 'react-redux';
/**
 * @param input
 * @param closeCallback
 * @param closeSheet
 * @param openDeleteModal
 * @param openRoleSheet
 * @param {User.Context} ctxUser
 * @returns {JSX.Element}
 * @constructor
 */
function UserEdit({input, closeSheet, isProfile, reFetch, token, user: ctxUser}) {
  const [user, setUser] = useState(isProfile ? ctxUser.data : input.current);
  const {validations, handleValidateForm} = useFormValidation(userRules({...user, isProfile}));
  const {editing: loading, handleEdit} = useEditApi({
    url: `${isProfile ? '/user_current_update' : '/user'}`,
    token,
    fullResp: isProfile
  });
  const handleUserChange = (key, value) => {
    setUser(prev => ({...prev, [key]: value}));
  };

  const handleSave = async () => {
    if (!handleValidateForm()) return;
    const success = await handleEdit(cleanEmptyField(user));
    if (isProfile) {
      if (success.data) {
        store.dispatch(login({...ctxUser, data: {role: ctxUser.data.role, ...success.data}}));
      } else {
        closeSheet();
        store.dispatch(setToast({content: success.message, error: true}));
        return;
      }
    }
    if (success) {
      if (user.id === ctxUser.data.id) {
        store.dispatch(login({...ctxUser, data: user}));
      }
      closeSheet();
      createMessage();
      !isProfile && reFetch();
    }
  };
  const userName = [user.first_name, user.last_name].join(' ');

  return (
    <>
      <SheetHeader handleClose={closeSheet} loading={loading}>
        <Stack alignment="center">
          <DisplayText size="small">
            {isProfile ? 'My Account' : `Edit user : ${userName}`}
          </DisplayText>
          <Stack.Item />
          <Stack.Item />
        </Stack>
      </SheetHeader>
      <SheetBody
        classNames={['HCC-Sheet__User']}
        handleClose={closeSheet}
        handleSave={handleSave}
        loading={loading}
      >
        <UserForm
          {...{user, handleUserChange, validations, isProfile, token, ctxUser}}
          disabled={loading}
        />
      </SheetBody>
    </>
  );
}
export default connect(({user}) => ({user}), {})(UserEdit);
UserEdit.propTypes = {
  input: PropTypes.object,
  closeSheet: PropTypes.func,
  openDeleteModal: PropTypes.func
};
