import React from 'react';
import {FormLayout, TextField} from '@shopify/polaris';
import useInput from '@assets/hooks/useInput';
import {useEffect} from 'react';
import {useState} from 'react';

function ChangePassword({input, validations = {}}) {
  const [data, handleDataChange] = useInput(input.current);

  useEffect(() => {
    input.current = {...data};
  }, [data]);
  const [inputType, setInputType] = useState({
    oldPassword: 'password',
    password: 'password',
    rePassword: 'password'
  });
  const type = ['password', 'text'];
  const handleChangeInput = (type, value) => {
    setInputType(prev => {
      return {
        ...prev,
        [type]: value
      };
    });
  };

  return (
    <div className="HCC-ChangePassword__Modal">
      <FormLayout>
        <div className="HCC-ChangePassword__Modal__InputWrapper">
          <div className="HCC-ChangePassword__Modal__Input">
            <TextField
              value={data.oldPassword}
              requiredIndicator
              label="Old Password"
              onChange={val => handleDataChange('oldPassword', val)}
              readOnly={false}
              autoComplete="off"
              type={inputType.oldPassword}
            />
          </div>
          <i
            className="far fa-eye-slash HCC-ChangePassword__Modal__togglePassword"
            onClick={() =>
              handleChangeInput(
                'oldPassword',
                type.find(x => x !== inputType.oldPassword)
              )
            }
          ></i>
        </div>
        <div className="HCC-ChangePassword__Modal__InputWrapper">
          <div className="HCC-ChangePassword__Modal__Input">
            <TextField
              value={data.password}
              requiredIndicator
              label="New Password"
              onChange={val => handleDataChange('password', val)}
              error={validations.password?.message || validations.oldPassword?.message}
              readOnly={false}
              autoComplete="off"
              type={inputType.password}
            />
          </div>
          <i
            className="far fa-eye-slash HCC-ChangePassword__Modal__togglePassword"
            onClick={() =>
              handleChangeInput(
                'password',
                type.find(x => x !== inputType.password)
              )
            }
          ></i>
        </div>
        <div className="HCC-ChangePassword__Modal__InputWrapper">
          <div className="HCC-ChangePassword__Modal__Input">
            <TextField
              requiredIndicator
              value={data.rePassword}
              label="Confirm New Password"
              onChange={val => handleDataChange('rePassword', val)}
              error={validations.rePassword?.message}
              readOnly={false}
              autoComplete="off"
              type={inputType.rePassword}
            />
          </div>
          <i
            className="far fa-eye-slash HCC-ChangePassword__Modal__togglePassword"
            onClick={() =>
              handleChangeInput(
                'rePassword',
                type.find(x => x !== inputType.rePassword)
              )
            }
          ></i>
        </div>
      </FormLayout>
    </div>
  );
}
export default ChangePassword;
