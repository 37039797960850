import {USER_ROLE_ADMIN, USER_ROLE_SELLER, USER_ROLE_SUPER_ADMIN} from '@assets/const/user/roles';
/**
 * @param {User.Info} data
 * @returns {any}
 */
export const userRules = data => {
  const rules = {
    firstName: {
      value: data.first_name,
      validation: 'required'
    },
    lastName: {
      value: data.last_name,
      validation: 'required'
    },
    email: {
      value: data.email,
      validation: 'required'
    },
    country: {
      value: data.country,
      validation: 'custom',
      message: 'This field is required',
      customHandler: () => {
        let isValid = false;
        if (data.country) {
          if (data.country.length > 0) isValid = true;
        }
        if (data.account_type === USER_ROLE_ADMIN || data.account_type === USER_ROLE_SUPER_ADMIN) {
          isValid = true;
        }
        return isValid;
      }
    },
    role: {
      value: data.role_id,
      validation: 'custom',
      message: 'This field is required',
      customHandler: () => {
        if (data.account_type !== 'admin') {
          return true;
        }
        let isValid = false;
        if (data.role_id) isValid = true;
        return isValid;
      }
    }
  };
  if (data.id && data.account_type === USER_ROLE_SELLER) {
    return {
      ...rules,
      seller_code: {
        value: data.seller_code,
        validation: 'required'
      }
    };
  }
  return rules;
};
