import {USER_ROLE_SUPER_ADMIN, USER_ROLE_SUPPLIER} from '@assets/const/user/roles';
import {checkPermission} from '@assets/helpers/user/permission';
import {Navigation} from '@shopify/polaris';
import {
  CashDollarMajor,
  CheckoutMajor,
  CustomersMajor,
  FolderDownMajor,
  HomeMajor,
  ProductsMajor,
  SettingsMajor,
  StoreMajor
} from '@shopify/polaris-icons';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

function AppNavigation({history, user}) {
  const pathname = history.location.pathname;
  const [selectedMenu, setSelectedMenu] = useState('');
  const isMenuSelected = path => [selectedMenu, pathname].some(x => x.startsWith(path));
  const handleMenuSelect = path => setSelectedMenu(prev => (prev === path ? '' : path));
  const definedAs = user.data.account_type;
  const isSupplier = definedAs === USER_ROLE_SUPPLIER;
  useEffect(() => {
    handleMenuSelect(selectedMenu);
  }, [pathname]);

  useEffect(() => {
    // Set roll up for Setting Menu
    const nodeList = Array.from(document.querySelectorAll('.Polaris-Navigation__ListItem'));
    nodeList
      ?.find(x => x.outerText.startsWith('Settings'))
      ?.classList.add('Polaris-Navigation__ListItem__isSettings');

    // Add Chevron for drop down menu
    const secondaryAction = Array.from(document.getElementsByClassName('Polaris-Navigation__Text'));
    secondaryAction
      ?.filter(x =>
        ['Users', 'Orders', 'Products', 'Billing Transaction'].some(p => x.outerText.startsWith(p))
      )
      .forEach(x => x.classList.add('ChevronIcon'));
    secondaryAction
      ?.filter(x => ['Settings'].some(p => x.outerText.startsWith(p)))
      .forEach(x => x.classList.add('ChevronIcon-settings'));
  }, []);

  return (
    <Navigation location="/home">
      <Navigation.Section
        items={[
          {
            scope: 'dashboard.view',
            label: 'Dashboard',
            icon: HomeMajor,
            url: '/home',
            selected: pathname === '/home',
            onClick: () => history.push('/home')
          },
          {
            scope: 'orders.view',
            label: 'Orders',
            icon: CheckoutMajor,
            url: '/orders',
            selected: isMenuSelected('/orders'),
            onClick: () => handleMenuSelect('/orders'),
            subNavigationItems: [
              {
                scope: 'orders.view',
                url: '/orders',
                label: 'All Orders',
                selected: pathname === '/orders'
              },
              {
                scope: definedAs === USER_ROLE_SUPPLIER ? '' : 'orders.view',
                url: '/orders/draft',
                label: 'Draft Order',
                selected: pathname === '/orders/draft'
              },
              {
                scope: '',
                url: '/orders/logs',
                label: 'Order Logs',
                selected: pathname === '/orders/logs'
              }
            ].filter(x => checkPermission(user, x.scope))
          },
          {
            scope: 'products.all_products.view',
            label: 'Products',
            icon: ProductsMajor,
            url: isSupplier ? '/products/types' : '/products',
            onClick: () => handleMenuSelect('/products'),
            selected: isMenuSelected('/products'),
            subNavigationItems: [
              {
                scope: 'products.all_products.view',
                url: '/products',
                label: 'All Products',
                selected: pathname === '/products'
              },
              {
                scope: 'products.categories.view',
                url: '/products/categories',
                label: 'Categories',
                selected: pathname === '/products/categories'
              },
              {
                scope: 'products.product_templates.view',
                url: '/products/templates',
                label: 'Product Templates',
                selected: pathname === '/products/templates'
              },
              {
                scope: 'products.product_types.view',
                url: '/products/types',
                label: 'Product Types',
                selected: pathname === '/products/types'
              }
            ].filter(x => checkPermission(user, x.scope))
          },
          {
            scope: 'user.all.view,user.role.view',
            label: `Users`,
            icon: CustomersMajor,
            url: '/users',
            onClick: () => handleMenuSelect('/users'),
            selected: isMenuSelected('/users'),
            subNavigationItems: [
              {
                scope: 'user.all.view',
                url: '/users',
                label: 'All Users',
                selected: pathname === '/users'
              },
              {
                scope: 'user.role.view',
                url: '/users/roles',
                label: 'Roles',
                selected: pathname === '/users/roles'
              }
            ].filter(x => checkPermission(user, x.scope))
          },
          {
            scope: 'sales_channels.view',
            label: 'Sales Channels',
            icon: StoreMajor,
            url: '/stores',
            selected: pathname.startsWith('/stores')
          },
          {
            scope: 'billing.view',
            label: 'Billing Transaction',
            icon: CashDollarMajor,
            url:
              (definedAs === USER_ROLE_SUPER_ADMIN && '/transaction/seller') ||
              checkPermission(user, 'billing_transaction.seller.view')
                ? '/transaction/seller'
                : '/transaction/supplier',
            selected: isMenuSelected('/transaction'),
            onClick: () => handleMenuSelect('/transaction'),
            subNavigationItems: [
              {
                scope: 'billing_transaction.seller.view',
                url: '/transaction/seller',
                label: 'Seller Transaction',
                selected: pathname === '/transaction/seller'
              },
              {
                scope: 'billing_transaction.supplier.view',
                url: '/transaction/supplier',
                label: 'Supplier Transaction',
                selected: pathname === '/transaction/supplier'
              }
            ].filter(x => checkPermission(user, x.scope))
          },
          {
            scope: 'import_export_history.view',
            label: 'Import/Export History',
            icon: FolderDownMajor,
            url: '/import-export-history',
            selected: pathname.startsWith('/import-export-history')
          }
        ].filter(x =>
          [x.scope, ...(x.subNavigationItems || []).map(y => y.scope)].some(x =>
            checkPermission(user, x)
          )
        )}
        fill={true}
      />

      <Navigation.Section
        icon={SettingsMajor}
        items={[
          {
            scope: 'settings.view',
            label: 'Settings',
            icon: SettingsMajor,
            url: '/news',
            onClick: () => handleMenuSelect('/carriers'),
            selected:
              [
                '/shipping-class',
                '/notifications',
                '/news',
                '/ware-house',
                '/notifications'
              ].some(p => isMenuSelected(p)) || pathname.includes('/carriers'),
            subNavigationItems: [
              {
                scope: 'settings.notifications.view',
                url: '/notifications',
                label: 'Notifications',
                selected: pathname.includes('/notifications')
              },
              {
                scope: 'settings.shipping-class.shipping-class.view',
                url: '/shipping-class',
                label: 'Shipping Class',
                selected: pathname.includes('/shipping-class') || pathname.includes('/carriers')
              },
              {
                scope: 'settings.ware_house.view',
                url: '/ware-house',
                label: 'Warehouse Origin',
                selected: pathname.includes('/ware-house')
              },
              {
                scope: 'settings.news.view',
                url: '/news',
                label: 'News',
                selected: pathname.includes('/news')
              }
            ].filter(x => checkPermission(user, x.scope))
          }
        ].filter(x =>
          [x.scope, ...x.subNavigationItems.map(y => y.scope)].some(x => checkPermission(user, x))
        )}
      />
    </Navigation>
  );
}
const mapStateToProps = app => ({
  router: app.router,
  user: app.user
});
export default connect(mapStateToProps, null)(withRouter(AppNavigation));
