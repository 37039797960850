import Order from '@assets/loadables/Order';
import OrderDraft from '@assets/loadables/OrderDraft';
import OrderLogs from '@assets/loadables/OrderLogs';
export default [
  {
    path: '/orders',
    component: Order,
    scope: 'orders.view'
  },
  {
    path: '/orders/draft',
    component: OrderDraft,
    scope: 'orders.view'
  },
  {
    path: '/orders/logs',
    component: OrderLogs,
    scope: ''
  }
];
