import {userTypes} from '../actions/user/userAction';
/**
 * A reducer contains all application state that related to authenticated user
 *
 * @param {Object} state
 * @param {string} type
 * @param {Object} payload
 * @return {{user}}
 */
export default function userReducer(state = {}, {type, payload}) {
  switch (type) {
    case userTypes.LOGIN:
      return {...state, ...payload, isLoggedIn: true};
    case userTypes.LOGOUT:
      return {...state, isLoggedIn: false};
    default:
      return state;
  }
}
