import {handleLogout} from '@assets/handlers/auth/handleAuth';
import {getUserToken} from '@assets/helpers';
import useConfirmModal from '@assets/hooks/modal/useConfirmModal';
import useConfirmSheet from '@assets/hooks/sheet/useConfirmSheet';
import UserEdit from '@assets/pages/User/UserEdit';
import {Button, Icon, Stack, TopBar} from '@shopify/polaris';
import {
  LogOutMinor,
  MobileHamburgerMajor,
  NotificationMajor,
  ProfileMinor
} from '@shopify/polaris-icons';
import {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import CollapseIcon from './collapseIcon';
import useFetchApi from '@assets/hooks/api/useFetchApi';
import {store} from '@assets/index';
import {login} from '@assets/actions/user/userAction';

/**
 * @param {User.Info} user
 * @param {Function} logout
 * @param {Object} history
 * @returns {JSX.Element}
 * @constructor
 */
function AppTopBar({user, logout, toggleMobileNavigation, toggleOpenNav, setTestState, isNavOpen}) {
  const token = getUserToken(user);
  const [isUserMenu, setIsUserMenu] = useState(false);

  const {
    data: {unread_notifications_count},
    fetched
  } = useFetchApi({
    url: '/news/count_unread',
    token
  });

  useEffect(() => {
    if (
      unread_notifications_count &&
      unread_notifications_count > user.data.unread_notifications_count
    ) {
      user.data.unread_notifications_count = unread_notifications_count;
      store.dispatch(login(user));
    }
  }, [fetched]);

  const toggleUserMenu = useCallback(() => setIsUserMenu(prev => !prev), []);
  const {modal: logoutModal, openModal: openLogoutModal} = useConfirmModal({
    confirmAction: async () => handleLogout(token),
    title: 'Log out',
    content: 'Are you sure you want to log out?',
    destructive: true
  });
  const userName = `${user.data.first_name} ${user.data.last_name}`;
  const {sheet, openSheet} = useConfirmSheet({
    Content: input => <UserEdit {...{...input, user, token, isProfile: true}} />,
    size: 'medium'
  });
  const userMenuActions = [
    {
      items: [
        {
          content: 'My account',
          icon: ProfileMinor,
          onAction: () => openSheet(user.data)
        },
        {content: 'Log out', icon: LogOutMinor, onAction: () => openLogoutModal()}
      ]
    }
  ];
  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={userName}
      avatar={''}
      detail={''}
      initials={userName.charAt(0)}
      open={isUserMenu}
      onToggle={toggleUserMenu}
    />
  );
  return (
    <TopBar
      searchField={<></>}
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigation}
      name={'Brian'}
      secondaryMenu={
        <div className="TopBar-ToggleNavigationButton">
          <Stack alignment="center">
            <Stack.Item fill>
              {isNavOpen ? (
                <Button plain onClick={toggleOpenNav} icon={MobileHamburgerMajor} />
              ) : (
                <Button plain onClick={toggleOpenNav}>
                  <CollapseIcon />
                </Button>
              )}
            </Stack.Item>
            <Stack.Item>
              <div style={{position: 'relative'}}>
                <Button plain url="/news">
                  <Icon source={NotificationMajor} />
                </Button>
                <span style={{color: 'white'}}>{user.data?.unread_notifications_count || ''}</span>
              </div>
            </Stack.Item>
          </Stack>
          {sheet}
          {logoutModal}
        </div>
      }
    />
  );
}

export default connect(({user}) => ({user}), {})(AppTopBar);
