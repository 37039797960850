import React, {useEffect} from 'react';
import CarrierForm from '@assets/pages/Settings/Carriers/CarrierForm/CarrierForm';
import useInput from '@assets/hooks/useInput';

const CarrierEdit = ({input, validations}) => {
  const [carrier, handleChangeData] = useInput(input.current);

  useEffect(() => {
    input.current = carrier;
  }, [carrier]);

  return (
    <div className="HCC-Carrier_Edit">
      <CarrierForm {...{carrier, validations, handleChangeData}} />
    </div>
  );
};

export default CarrierEdit;
