import prepareLabel from '@assets/helpers/utils/prepareLabel';
import {
  Button,
  Icon,
  InlineError,
  OptionList,
  Popover,
  Scrollable,
  Stack,
  Tag,
  TextField,
  TextStyle
} from '@shopify/polaris';
import {CancelSmallMinor, SearchMinor} from '@shopify/polaris-icons';
import React, {useCallback, useEffect, useState} from 'react';

/**
 * @param list
 * @param defaultList
 * @param onChange
 * @param {string[]|string} input
 * @param error
 * @param loading
 * @param disabled
 * @param multiple
 * @param includeTagList
 * @param usePopover
 * @param singular
 * @param plural
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
export default function OptionListSelect({
  list = [],
  defaultList = [],
  onChange,
  input,
  error = false,
  loading = false,
  disabled = false,
  multiple = false,
  includeTagList = true,
  usePopover = true,
  singular = 'item',
  plural = '',
  placeholder = 'Please select',
  removeBtn = true,
  createErrorMessage = ''
}) {
  const allList = [...list, ...defaultList];
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const defaultDisplay = 100;
  const [displayCount, setDisplayCount] = useState(defaultDisplay);

  useEffect(() => {
    setDisplayCount(defaultDisplay);
  }, [open]);

  useEffect(() => {
    if (!multiple) {
      setOpen(false);
    }
  }, [input]);

  useEffect(() => {
    // set default value as empty when no option found
    if (
      !loading &&
      input?.length &&
      !allList.find(item => (multiple ? input.includes(item.value) : input === item.value))
    ) {
      onChange(multiple ? [] : '');
    }
  }, [input, loading, allList]);

  const handleRemove = useCallback(
    value => () => {
      onChange(input.filter(item => item !== value));
    },
    [input]
  );

  const prepareBtnLabel = () => {
    if (input?.length) {
      if (multiple) {
        return prepareLabel(input.length, singular, plural) + ' selected';
      }
      const selected = allList.find(item =>
        multiple ? input.includes(item.value) : input === item.value
      );

      if (selected) {
        return selected.label;
      }
    }
    return placeholder;
  };

  const prepareOptions = () => {
    if (search === '') {
      return list;
    }
    const searchValue = search.toLowerCase().trim();
    return list.filter(item =>
      [item.id, item.label, item.subTitle]
        .filter(x => x)
        .some(str => str.toLowerCase().includes(searchValue))
    );
  };
  const options = prepareOptions();

  const activator = (
    <Stack alignment="center" spacing="tight">
      <Stack.Item fill>
        <Button
          loading={loading}
          onClick={() => setOpen(prev => !prev)}
          disclosure
          fullWidth
          outline={error}
          destructive={error}
          textAlign="left"
          disabled={disabled}
        >
          {prepareBtnLabel()}
        </Button>
        <div style={{marginTop: '0.3rem'}}>
          {error && <InlineError message={createErrorMessage}></InlineError>}
        </div>
      </Stack.Item>
      {input.length > 0 && removeBtn && (
        <Button plain onClick={() => onChange(multiple ? [] : '')} disabled={disabled || loading}>
          <Icon source={CancelSmallMinor} backdrop />
        </Button>
      )}
    </Stack>
  );

  const searchBar = (
    <TextField
      prefix={<Icon source={SearchMinor} />}
      label=""
      labelHidden
      placeholder="Search"
      autoComplete="off"
      value={search}
      onChange={v => setSearch(v)}
    />
  );

  const listOptions =
    options.length === 0 ? (
      <div style={{textAlign: 'center', padding: '1.6rem'}}>No result found</div>
    ) : (
      <>
        <OptionList
          allowMultiple={multiple}
          selected={input}
          onChange={val => onChange(multiple ? val : val[0])}
          options={options.slice(0, displayCount).map(option => ({
            value: option.value,
            label: option.subTitle ? (
              <Stack vertical spacing="extraTight">
                <TextStyle>{option.label}</TextStyle>
                <TextStyle variation="subdued">{option.subTitle}</TextStyle>
              </Stack>
            ) : (
              option.label
            )
          }))}
        />
        {options.length > displayCount && (
          <div style={{marginLeft: '1rem'}}>
            <Button plain onClick={() => setDisplayCount(prev => prev + defaultDisplay)}>
              {`Show more ${defaultDisplay} results`}
            </Button>
          </div>
        )}
      </>
    );

  const popover = usePopover ? (
    <Popover
      preferredAlignment="left"
      fluidContent
      active={open}
      onClose={() => setOpen(false)}
      activator={activator}
    >
      <Popover.Section>{searchBar}</Popover.Section>
      <Scrollable style={{maxHeight: '20rem', marginTop: '-1.6rem'}}>{listOptions}</Scrollable>
    </Popover>
  ) : (
    <>
      {searchBar}
      <Scrollable style={{overflow: 'hidden', margin: '0 -1rem'}}>{listOptions}</Scrollable>
    </>
  );

  const findItem = value => allList.find(option => option.value === value);
  const checkedItems = multiple ? input.map(item => findItem(item)).filter(x => x) : [];

  const tagList = checkedItems.length > 0 && (
    <Stack spacing="tight">
      {checkedItems.map((item, index) => (
        <Tag key={index} onRemove={handleRemove(item.value)} disabled={disabled || loading}>
          {item.label}
        </Tag>
      ))}
    </Stack>
  );

  return includeTagList ? (
    <Stack vertical spacing="tight">
      {popover}
      {tagList}
    </Stack>
  ) : (
    popover
  );
}
