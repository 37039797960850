export default function sheetBackdrop({open = false, type = 'confirm'}) {
  // Add backdrop when open change password modal
  if (type === 'confirm') {
    if (document.querySelector('.Polaris-Sheet__Container') && open) {
      if (
        !document.querySelector(
          '.Polaris-Sheet__Container .Polaris-Backdrop.Polaris-Backdrop__Confirm'
        )
      ) {
        const backDrop = document.createElement('div');
        backDrop.classList.add('Polaris-Backdrop');
        backDrop.classList.add('Polaris-Backdrop__Confirm');
        document.querySelectorAll('.Polaris-Sheet__Container').forEach(x => {
          x.appendChild(backDrop);
        });
      }
    } else {
      if (
        document.querySelector(
          '.Polaris-Sheet__Container .Polaris-Backdrop.Polaris-Backdrop__Confirm'
        )
      ) {
        document.querySelector('.Polaris-Backdrop.Polaris-Backdrop__Confirm').remove();
      }
    }
  } else {
    if (document.querySelector('.Polaris-Sheet__Container') && open) {
      if (
        !document.querySelector(
          '.Polaris-Sheet__Container .Polaris-Backdrop.Polaris-Backdrop__View'
        ) &&
        !document.querySelector(
          '.Polaris-Sheet__Container .Polaris-Backdrop.Polaris-Backdrop__Confirm'
        )
      ) {
        const backDrop = document.createElement('div');
        backDrop.classList.add('Polaris-Backdrop');
        backDrop.classList.add('Polaris-Backdrop__View');
        document.querySelectorAll('.Polaris-Sheet__Container').forEach(x => {
          x.appendChild(backDrop);
        });
      }
    } else {
      if (
        document.querySelector('.Polaris-Sheet__Container .Polaris-Backdrop.Polaris-Backdrop__View')
      ) {
        document.querySelector('.Polaris-Backdrop.Polaris-Backdrop__View').remove();
      }
    }
  }
}
