import Transaction from '@assets/loadables/Transaction';

export default [
  {
    path: '/transaction/seller',
    component: Transaction,
    scope: 'billing_transaction.seller.view'
  },
  {
    path: '/transaction/supplier',
    component: Transaction,
    scope: 'billing_transaction.supplier.view'
  }
];
