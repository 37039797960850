export const defaultSortOrders = [
  {label: 'ID (newest first)', order_by: 'id', order_dir: 'desc'},
  {label: 'ID (oldest first)', order_by: 'id', order_dir: 'asc'},
  {label: 'Created Date (oldest first)', order_by: 'created_at', order_dir: 'asc'},
  {label: 'Created Date (newest first)', order_by: 'created_at', order_dir: 'desc'}
];

export const defaultBillingSortOrders = [
  {label: 'Created Date (oldest first)', order_by: 'created_at', order_dir: 'asc'},
  {label: 'Created Date (newest first)', order_by: 'created_at', order_dir: 'desc'}
];
