import {useState} from 'react';
import Http from '@assets/services/Http';
import {store} from '@assets/index';
import {setToast} from '@assets/actions/layout/setToastAction';

/**
 *
 * @param url
 * @param token
 * @param fullResp
 * @returns {{deleting: boolean, handleDelete: ((function(*): Promise<boolean>)|*)}}
 */
export default function useDeleteApi({url, token, fullResp = false}) {
  const [deleting, setDeleting] = useState(false);

  /**
   * @param data
   * @returns {Promise<boolean>}
   */
  const handleDelete = async data => {
    try {
      setDeleting(true);
      const resp = await Http({url: `${url}?id=${data}`, method: 'DELETE', token});
      const success = resp.success;
      // if (success) {
      //   store.dispatch(setToast({content: resp.message || 'Deleted successfully'}));
      //   return success;
      // } else {
      //   store.dispatch(setToast({content: resp.message, error: true}));
      // }
      if (!success) {
        store.dispatch(setToast({content: resp.message, error: true}));
      }

      return fullResp ? resp : success;
    } catch (e) {
      console.log(e);
      store.dispatch(setToast({content: 'Failed to delete', error: true}));
    } finally {
      setDeleting(false);
    }
  };

  return {deleting, handleDelete};
}
