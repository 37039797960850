import {setToast} from '@assets/actions/layout/setToastAction';
import {store} from '@assets/index';
import Http from '@assets/services/Http';
import {useState} from 'react';
/**
 * @param url
 * @param token
 * @param fullResp
 * @param successMsg
 * @param createErrorMessage
 * @returns {{creating: boolean, handleCreate}}
 */
export default function useCreateApi({
  url,
  token = '',
  successMsg = 'Saved successfully',
  createErrorMessage = 'Failed to save',
  fullResp = false
}) {
  const [creating, setCreating] = useState(false);
  /**
   * @param data
   * @returns {Promise<{success: boolean, error}>}
   */
  const handleCreate = async data => {
    try {
      setCreating(true);
      const resp = await Http({url, data, method: 'POST', token});
      const success = resp.success;
      // if (success) {
      //   store.dispatch(setToast({content: resp.message || successMsg}));
      // }

      if (!success) {
        store.dispatch(setToast({content: resp.message, error: true}));
      }
      return fullResp ? resp : success;
    } catch (e) {
      console.log(e);
      store.dispatch(setToast({content: createErrorMessage, error: true}));
      return fullResp ? {success: false, error: e.message} : false;
    } finally {
      setCreating(false);
    }
  };

  return {creating, handleCreate};
}
