import React from 'react';
import {Spinner} from '@shopify/polaris';
import NotFound from '../../pages/NotFound';

export default function Loading({error, pastDelay = true, retry, timedOut}) {
  if (error) {
    console.error(error);
    return <NotFound retry={retry} />;
  }

  // require "timeout" option in loadable component
  if (timedOut) {
    console.error('Taking too long to load', timedOut);
    return <NotFound retry={retry} />;
  }

  if (pastDelay) {
    return (
      <div className="PreLoading" style={{position: 'absolute'}}>
        <Spinner />
      </div>
    );
  }

  return null;
}
