import Routes from './routes/routes';
import {history} from './helpers';
import {Router} from 'react-router-dom';
import {AppProvider} from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import theme from './config/theme';
import ReactRouterLink from './components/ReactRouterLink';

function App() {
  return (
    <AppProvider i18n={translations} theme={theme} linkComponent={ReactRouterLink}>
      <Router history={history}>
        <Routes />
      </Router>
    </AppProvider>
  );
}

export default App;
