import React from 'react';
import Home from '../loadables/Home';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Login from '@assets/loadables/Auth/Login';
import AppLayout from '@assets/components/AppLayout';
import ForgotPassword from '@assets/loadables/Auth/ForgotPassword';
import CreatePassword from '@assets/loadables/Auth/CreatePassword';
import {checkPermission} from '@assets/helpers/user/permission';
//
import userRoutes from './userRoutes';
import productRoutes from './productRoutes';
import orderRoutes from './orderRoutes';
import settingsRoutes from '@assets/routes/settingsRoutes';
import storeRoutes from '@assets/routes/storeRoutes';
import importExportHistory from '@assets/routes/importExportHistory';
import transactionRoutes from './transactionRoutes';
const Routes = ({user}) => {
  if (user.isLoggerIn) {
    <Redirect exact from="*" to="/login" />;
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/auth/verify" component={CreatePassword} />
      <Route exact path="/auth/resetPassword" component={CreatePassword} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Redirect exact from="/" to="/login" />
      {user.isLoggedIn ? (
        <AppLayout>
          <Route exact path="/home" component={checkPermission(user, 'dashboard.view') && Home} />
          {[
            ...userRoutes,
            ...productRoutes,
            ...settingsRoutes,
            ...orderRoutes,
            ...storeRoutes,
            ...importExportHistory,
            ...transactionRoutes
          ]
            .filter(x => x.scope.split(',').some(p => checkPermission(user, p)))
            .map((props, key) => (
              <Route key={key} exact {...props} />
            ))}
        </AppLayout>
      ) : (
        <Redirect exact from="*" to="/login" />
      )}
    </Switch>
  );
};
export default connect(({user}) => ({user}), null)(Routes);
