import User from '../loadables/User';
import Role from '../loadables/User/Role';
export default [
  {path: '/users', component: User, scope: 'user.all.view'},
  {
    path: '/users/roles',
    component: Role,
    scope: 'user.role.view'
  }
];
