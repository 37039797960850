import {defaultChangePasswordData} from '@assets/config/userConfig';
import {changePasswordRules} from '@assets/config/validations/authRules';
import {allCountries} from '@assets/const/countries';
import {timezones} from '@assets/const/timezones';
import {createErrorMessage, createMessage} from '@assets/const/toast/toastConst';
import {USER_ROLE_ADMIN, USER_ROLE_SELLER} from '@assets/const/user/roles';
import {UserGridContext} from '@assets/contexts/user/userGridContext';
import {handleChangePassword} from '@assets/handlers/auth/handleAuth';
import toOption from '@assets/helpers/utils/toOptions';
import useConfirmModal from '@assets/hooks/modal/useConfirmModal';
import {
  Button,
  Card,
  FormLayout,
  Labelled,
  Select,
  Stack,
  TextField,
  TextStyle
} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import {useContext, useMemo, useState} from 'react';
import ChangePassword from '../Modal/changePassword';
import {checkRole} from '@assets/helpers/user/permission';
/**
 * @param {User.Info} user
 * @param validations
 * @param handleUserChange
 * @param disabled
 * @param page
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserForm({
  user,
  ctxUser = {},
  handleUserChange,
  validations,
  disabled,
  isProfile,
  token
}) {
  const {roles} = useContext(UserGridContext);
  const roleOptions = useMemo(
    () =>
      (roles || []).map(x => {
        return {value: x.id, label: x.name};
      }),
    [roles]
  );
  const countryOptions = useMemo(() => toOption(allCountries), []);
  const [loading, setLoading] = useState(false);
  const {openModal, modal: changePasswordModal} = useConfirmModal({
    confirmAction: async input => {
      setLoading(true);
      const {oldPassword: old_password, password} = input;
      const email = user.email;
      const resp = await handleChangePassword(email, old_password, password, token);
      if (resp.success) {
        setLoading(false);
        createMessage('Change password successfully !');
      } else {
        setLoading(false);
        createErrorMessage(resp.message);
      }
    },
    title: 'Change Password',
    validationRules: changePasswordRules,
    HtmlContent: ({input, validations}) => <ChangePassword {...{input, validations}} />,
    loading: loading,
    buttonTitle: 'Submit'
  });

  return (
    <>
      <div className="HCC-Sheet__User-FormWrapper-Overview">
        <Stack vertical>
          <TextStyle variation="strong">Overview</TextStyle>
          <Card sectioned>
            <FormLayout>
              {user.id && user?.account_type === USER_ROLE_SELLER && checkRole(ctxUser) && (
                <div className="HCC_User__SellerCode">
                  <TextField
                    requiredIndicator
                    label="Seller Code"
                    maxLength={25}
                    value={user?.seller_code}
                    onChange={val => handleUserChange('seller_code', val)}
                    error={validations?.seller_code?.message}
                    readOnly={disabled}
                    autoComplete="off"
                    disabled={disabled}
                  />
                </div>
              )}
              <FormLayout.Group>
                <TextField
                  requiredIndicator
                  label="First Name"
                  value={user.first_name}
                  onChange={val => handleUserChange('first_name', val)}
                  error={validations.firstName?.message}
                  readOnly={disabled}
                  autoComplete="off"
                  disabled={disabled}
                />
                <TextField
                  requiredIndicator
                  label="Last Name"
                  value={user.last_name}
                  onChange={val => handleUserChange('last_name', val)}
                  error={validations.lastName?.message}
                  disabled={disabled}
                  autoComplete="off"
                />
              </FormLayout.Group>
              {isProfile ? (
                <FormLayout.Group>
                  <TextField
                    type="email"
                    inputMode="email"
                    requiredIndicator
                    label="Email"
                    value={user.email}
                    onChange={val => handleUserChange('email', val)}
                    error={validations.email?.message}
                    disabled={disabled}
                    autoComplete="off"
                  />
                  <Labelled label="&nbsp;">
                    <Button onClick={() => openModal(defaultChangePasswordData)}>
                      Change Password
                    </Button>
                  </Labelled>
                </FormLayout.Group>
              ) : (
                <TextField
                  type="email"
                  inputMode="email"
                  requiredIndicator
                  label="Email"
                  value={user.email}
                  onChange={val => handleUserChange('email', val)}
                  error={validations.email?.message}
                  disabled={disabled}
                  autoComplete="off"
                />
              )}
              <FormLayout.Group>
                <TextField
                  inputMode="tel"
                  type="number"
                  label="Phone Number"
                  value={user.phone}
                  onChange={val => handleUserChange('phone', val)}
                  error={validations.phone?.message}
                  disabled={disabled}
                  autoComplete="off"
                />
                <Select
                  label="Timezone"
                  placeholder="Please select"
                  value={user.timezone}
                  options={toOption(timezones)}
                  onChange={val => handleUserChange('timezone', val)}
                  error={validations.timezone?.message}
                  disabled={disabled}
                />
              </FormLayout.Group>
              {user.account_type === USER_ROLE_ADMIN && !isProfile && (
                <FormLayout.Group>
                  <Select
                    label="Select Roles"
                    requiredIndicator
                    placeholder="Please select"
                    value={user.role_id}
                    options={roleOptions}
                    onChange={val => handleUserChange('role_id', parseInt(val))}
                    error={validations.role?.message}
                    disabled={disabled}
                  />
                  <></>
                </FormLayout.Group>
              )}
              <TextField
                label="Admin Note"
                value={user.admin_note}
                onChange={val => handleUserChange('admin_note', val)}
                readOnly={disabled}
                autoComplete="off"
                disabled={disabled}
                multiline={2}
              />
            </FormLayout>
          </Card>
        </Stack>
      </div>
      {(user.account_type !== USER_ROLE_ADMIN || isProfile) && (
        <div className="HCC-Sheet__User-FormWrapper-Address">
          <Stack vertical>
            <TextStyle variation="strong">Address</TextStyle>
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Address"
                  value={user.address}
                  onChange={val => handleUserChange('address', val)}
                  readOnly={disabled}
                  autoComplete="off"
                  disabled={disabled}
                />
                <FormLayout.Group>
                  <TextField
                    label="City"
                    value={user.city}
                    onChange={val => handleUserChange('city', val)}
                    readOnly={disabled}
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <TextField
                    label="Region"
                    value={user.region}
                    onChange={val => handleUserChange('region', val)}
                    readOnly={disabled}
                    autoComplete="off"
                    disabled={disabled}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label="Company"
                    value={user.company}
                    onChange={val => handleUserChange('company', val)}
                    error={validations.company?.message}
                    disabled={disabled}
                    autoComplete="off"
                  />
                  <TextField
                    label="Postal Code"
                    value={user.postal_code}
                    onChange={val => handleUserChange('postal_code', val)}
                    error={validations.postal_code?.message}
                    disabled={disabled}
                    autoComplete="off"
                    type="number"
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    label="Country/Origin"
                    placeholder="Please select"
                    requiredIndicator={isProfile ? false : true}
                    value={user.country}
                    options={countryOptions}
                    onChange={val => handleUserChange('country', val)}
                    error={validations.country?.message}
                    disabled={disabled}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
          </Stack>
        </div>
      )}
      {changePasswordModal}
    </>
  );
}

UserForm.propTypes = {
  user: PropTypes.object,
  handleUserChange: PropTypes.func,
  validations: PropTypes.object,
  disabled: PropTypes.bool,
  page: PropTypes.string
};
