import ProductTypes from '@assets/loadables/Product/ProductTypes';
import Category from '@assets/loadables/Product/Category';
import ProductGrid from '@assets/loadables/Product/ProductGrid';
import ProductTemplates from '@assets/loadables/Product/ProductTemplates';
export default [
  {
    path: '/products',
    component: ProductGrid,
    scope: 'products.all_products.view'
  },
  {
    path: '/products/categories',
    component: Category,
    scope: 'products.categories.view'
  },
  {
    path: '/products/templates',
    component: ProductTemplates,
    scope: 'products.product_templates.view'
  },
  {
    path: '/products/types',
    component: ProductTypes,
    scope: 'products.product_types.view'
  }
];
