import Http from '../Http';

export async function LoginApi(email, password) {
  return Http({url: '/auth/login', method: 'POST', params: {email, password}});
}

export async function LogoutApi(token) {
  return Http({url: '/auth/logout', method: 'DELETE', token: token});
}

export async function VerifyCreateToken(code) {
  return Http({url: '/auth/verify', params: {code}});
}

export async function CreatePassword(code, password, isCreate = false) {
  return Http({url: '/auth/resetPassword', method: 'POST', data: {code, password, isCreate}});
}

export async function SendMailResetPassword(email) {
  return Http({url: '/auth/forgotPassword', params: {email}});
}

export async function ChangePassword(email, old_password, password, token) {
  return Http({
    url: 'auth/changePassword',
    method: 'POST',
    data: {email, old_password, password},
    token
  });
}
