export const loginRules = data => ({
  email: {
    value: data.email,
    validation: 'required|email'
  },
  password: {
    value: data.password,
    validation: 'min_char|required',
    comparedValue: 8,
    isEqual: true
  }
});

export const createPasswordRules = data => ({
  password: {
    value: data.password,
    validation: 'min_char',
    comparedValue: 8,
    isEqual: true,
    message: 'Password Must Be At Least 8 Characters Long !'
  },
  rePassword: {
    value: data.rePassword,
    validation: 'custom',
    message: 'Both passwords must match !',
    customHandler: () => {
      let isValid = true;
      if (data.password.length === 0 || data.rePassword.length === 0) {
        isValid = false;
      }
      if (data.password !== data.rePassword) {
        isValid = false;
      }
      return isValid;
    }
  }
});

export const resetPasswordRules = data => ({
  email: {
    value: data,
    validation: 'required|email'
  }
});

export const changePasswordRules = data => ({
  oldPassword: {
    value: data.oldPassword,
    validation: 'required|custom',
    message: 'New password must be different from current password !',
    customHandler: () => {
      let isValid = true;
      if (data.oldPassword === data.password) {
        isValid = false;
      }
      return isValid;
    }
  },
  password: {
    value: data.password,
    validation: 'min_char',
    comparedValue: 8,
    isEqual: true,
    message: 'Password Must Be At Least 8 Characters Long !'
  },
  rePassword: {
    value: data.rePassword,
    validation: 'custom',
    message: 'Both passwords must match !',
    customHandler: () => {
      let isValid = true;
      if (data.password.length === 0 || data.rePassword.length === 0) {
        isValid = false;
      }
      if (data.password !== data.rePassword) {
        isValid = false;
      }
      return isValid;
    }
  }
});
