import {USER_ROLE_ADMIN, USER_ROLE_SUPER_ADMIN} from '@assets/const/user/roles';

/**
 * @param {User.Info} user
 * @returns {boolean}
 */

export const checkRole = user => {
  return [USER_ROLE_ADMIN, USER_ROLE_SUPER_ADMIN].some(x => x === user.data.account_type);
};
/**
 * @param {User.Info} user
 * @param {Scope} scope
 * @returns {boolean}
 */
export const checkPermission = (user, scope) => {
  const {account_type} = user.data;
  if (account_type === USER_ROLE_SUPER_ADMIN) return true;
  const {resources: prepareResources} = user.data.role || '';
  const resources = prepareResources?.split(',');
  if (resources?.includes('all')) return true;
  return resources?.some(x => x === scope);
};
