import {setToast} from '@assets/actions/layout/setToastAction';
import {logout} from '@assets/actions/user/userAction';
import axios from 'axios';
import {store} from '..';
import {BASE_API} from './const/base';

export async function Http({url, data = {}, method = 'GET', params = {}, token = ''}) {
  const client = axios.create({
    baseURL: BASE_API,
    timeout: 600000
  });

  params = {...params, XDEBUG_SESSION_START: 'PHPSTORM'};

  return client
    .request({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      data,
      params
    })
    .then(res => res.data)
    .catch(function(error) {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(logout());
          store.dispatch(
            setToast({
              isToast: true,
              content: 'Your last login session has expired, please re-login !'
            })
          );
        }
        return error.response.data;
      }
    });
}
export default Http;
