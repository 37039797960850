import {createBrowserHistory} from 'history';
import * as firebase from 'firebase/app';
import 'firebase/storage';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
});
export const storage = firebase.storage();
export const history = createBrowserHistory();

export function getUserToken(user) {
  return `Bearer ${user.meta.access_token}`;
}
