import {ChoiceList, DatePicker} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';

export default function DateOptionsFilter({
  appliedFilters,
  handleFilterChange,
  loading,
  choices,
  field = 'time'
}) {
  const today = new Date();
  const [{month, year}, setDate] = useState({month: today.getMonth(), year: today.getFullYear()});
  const handleMonthChange = useCallback((month, year) => setDate({month, year}), []);
  return (
    <>
      <ChoiceList
        key={field}
        title=""
        titleHidden
        choices={choices}
        selected={appliedFilters[field]}
        onChange={val => handleFilterChange(field, val[0])}
        disabled={loading}
      />
      {appliedFilters[field] === 'custom' && (
        <>
          <br />
          <DatePicker
            allowRange
            multiMonth
            weekStartsOn={1}
            onMonthChange={handleMonthChange}
            month={month}
            year={year}
            selected={appliedFilters[`${field}Custom`]}
            onChange={val => {
              if (loading) return;
              const {start, end} = val;
              handleFilterChange(`${field}Custom`, val, true);
              handleFilterChange(`${field}From`, new Date(start).toISOString(), true);
              handleFilterChange(`${field}To`, new Date(end).toISOString(), true);
            }}
          />
        </>
      )}
    </>
  );
}
