import {connectRouter, routerMiddleware} from 'connected-react-router';
import {applyMiddleware, combineReducers, createStore as createStoreRedux} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
// import rootSaga from '../actions/rootSaga';
import {persistReducer} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';
import layoutReducer from './layoutReducer';
import userReducer from './userReducer';

/**
 * Generate a reducer store from a configuration
 * @param {object} history
 * @return {object}
 */
export default function createStore(history) {
  const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel1,
    whitelist: ['user']
  };
  const rootReducer = combineReducers({
    router: connectRouter(history),
    layout: layoutReducer,
    user: userReducer
  });
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();
  const store = createStoreRedux(
    persistedReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
  // sagaMiddleware.run(rootSaga);
  return store;
}
